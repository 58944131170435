* {
  margin:0px;
  padding:0px;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #60b8cc;
  background-color:rgb(129, 216, 236);
}

@media screen and (min-width:1000px) {
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #60b8cc;
  background-color:rgb(129, 216, 236);
  overflow-x: hidden;
}
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


h1, h2, h3 {
  color: hsl(189, 100%, 82%);
  text-align:center;
  margin-top: 10px;
}


.App {
  background-color: #60b8cc;
  background-color:rgb(129, 216, 236);
  min-height: 100vh;
  max-width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color:hsl(189, 100%, 82%);

}

.App-link {
  color: #61dafb;
}


.albumart {
  width: 450px;
  height: auto;
  border: solid 3px rgb(40, 184, 194);
  border-radius: 5px;
}
#playlist
{
  font-size: 18px;
  color:white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style-type: none;
}

.description {
  font-size: 12px;
  color:hsl(189, 100%, 82%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
}

#addAlbum {
  height:100vh;
  width:100vw;
  background-color:rgba(83, 194, 194, 0.774);
}

#menü{ 
  height:100vh;
  width:100vw;
  background-color:rgb(7, 62, 78);
}

nav {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:150px;
  padding:10px 40px;
  box-sizing:border-box;
  transition: 2s;
  overflow-y: hidden;
  
}


nav .logo {
  float: left;
}

nav .logo img {

  height:80px;
  width:80px;
  transition: .5s;
  float:left;
  border-radius: 50%;
  border:1px solid black
}


#centerPic  img{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  height: 250px;
  padding: 6px;  
  background: rgb(121, 25, 25);
  color: white;
  text-align: center;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  border-radius:50%;
  opacity: 0;
  animation: logo;
  animation-delay: 2s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  transition-property: transform;
  transition-duration: 3s;
}

@keyframes logo {
  0% {opacity:0}
  100%{opacity:1}
  }

  #logoLeft
{
  animation:left;
  animation-duration:3s;
  font-size:"100px";
  font-family: 'Raleway, sans-serif';
  position:"absolute";
  color:"#F3F3F3";
  top:"200px";
  left:"200px";
}


#logoRight
{
  font-size:"100px";
  font-family: 'Raleway, sans-serif';
  position:"absolute";
  color:"white";
  bottom:"200px";
  right:"200px";
  animation:right;
  animation-duration:3s;
  overflow-x: hidden;
}

@media screen and(min-width:800px)
{
  #logoRight
{
  font-size:"100px";
  font-family: 'Raleway, sans-serif';
  position:"absolute";
  color:"white";
  bottom:"200px";
  right:"200px";
  animation:right;
  animation-duration:3s;
  overflow-x: hidden;
}

}



@keyframes left {

  0% {opacity:0}
  25% {opacity:0.5}
  100%{opacity:1}
  0%{ transform: translateX(-650px); }
  100%{ transform: translateX(0px); }
  
  }

  @keyframes right {

    0% {opacity: 0}
    25% {opacity:0.5}
    100%{opacity:1}
    0%{ transform: translateX(650px); }
    100%{ transform: translateX(0px); }

  }


@media screen and (max-width:800px)
{
  nav .logo img {

    height:40px;
    width:40px;
    transition: .5s;
    float:left;
    border-radius: 50%;
    border:1px solid black
  }  
  
nav ul li a 
{
  line-height:80px;
  color:rgb(11, 42, 43); 
  color:white;
  font-size: 0.7em;
  padding:5px 10px;
  text-decoration: none;
  text-transform: uppercase;
  transition: .5s;
  margin-left:10px;
}

#centerPic  img{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
  padding: 6px;  
  background: rgb(121, 25, 25);
  color: white;
  text-align: center;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  border-radius:50%;
  opacity: 0;
  animation: logo;
  animation-delay: 4s;
  animation-duration: 5s;
  animation-fill-mode: forwards;
  transition-property: transform;
  transition-duration: 6s;
}






@keyframes left {

  0% {opacity:0}
  25% {opacity:0.5}
  100%{opacity:1}
  0%{ transform: translateX(-650px); }
  100%{ transform: translateX(0px); }
  
  }

  @keyframes right {

    0% {opacity: 0}
    25% {opacity:0.5}
    100%{opacity:1}
    0%{ transform: translateX(650px); }
    100%{ transform: translateX(0px); }

  }

}

nav ul 
{
  float:right;
  margin: 0px;
  padding:0px;
  display:flex;
}

nav ul li 
{
  list-style:none;
}

nav ul li a 
{
  line-height:80px;
  color:rgb(11, 42, 43); 
  color:white;
  padding:5px 10px;
  text-decoration: none;
  text-transform: uppercase;
  transition: .5s;
  margin-left:10px;

}

 nav.black
 {
   background:rgba(0,0,0,.9);
   height:80px;
   padding:10px 10px;
   overflow-y: hidden;
 }



 nav.black .logo img 
 {
   height:50px;
   width:50px;
   border:1px solid black;
   transition:.5s;
   
 }

 nav.black ul li a 
 {
   color:#fff;
   line-height:60px;
 }

 nav.black ul li a:hover
 {
   color:rgb(13, 127, 153);
   line-height:60px;
 }



 #footer
 {
   background-color: #000000;
   position: fixed;
   padding-bottom: 50px;
   bottom: 0;
   height:80px;
   width: 100%;
   color: white;
   text-align: center;
 }

  p 
 {
  font-size:15px;
  text-align: center;
  padding: 25px;
 }
 #dl_link {
  font-size:30px;
  font-weight: bold;
  font-family: "Helvetica", sans-serif;
}
a:link {
  color: rgb(7, 98, 126);
}
a:visited {
  color:  rgb(7, 98, 126);
}
a:hover {
   color:orange;
   border-radius:5%;
 }

.comingsoon {
  font-size: 30px;
  font-weight: bold;
  font-family: "Helvetica", sans-serif;
  color: rgb(7, 98, 126);
}

 .headers
{
  font-size: 50px;
  margin:100px;
  margin-bottom:10vh;
  margin-top:200px;
  color:#fff;
  animation: header;
  animation-duration:2s;
}

@media screen and (max-width:800px){

  
 .headers
 {
   font-size: 25px;
   margin:100px;
   margin-bottom:10vh;
   margin-top:200px;
   color:#fff;
   animation: header;
   animation-duration:2s;
 }


}

@keyframes header {
0% {opacity:0}
25% {opacity:0.5}
100%{opacity:1}
0%{ transform: translateY(-100px); }
100%{ transform: translateY(0px); }
}

#news
{
  animation:logo;
  animation-duration:4s;

}

@media screen and (max-width:700px)
{
  
#news
{
  animation:logo;
  animation-duration:4s;
  height:750px;
  width:450px;

}


}

.text_about{
  margin:50px;
  margin-top:10vh;
  margin-bottom:100px;
  width:60%;
  margin-left:auto;
  margin-right:auto;
  padding:40px;
  font-size:24px;
  color: rgb(7, 98, 126);
  text-align: justify;
  font-weight: 100;
  animation:logo;
  animation-duration:4s;
  overflow-x: hidden;
}

@media screen and(max-width:800px){

  #text_about{
    margin:50px;
    margin-top:10vh;
    margin-bottom:100px;
    width:60%;
    margin-left:auto;
    margin-right:auto;
    padding:40px;
    font-size:12px;
    color: #ffffff;
    text-align: justify;
    font-weight: 100;
    animation:logo;
    animation-duration:4s;
    overflow-x: hidden;
  }


}


video{
  width:60%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom:100px;
}
.video {
  width:60%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom:100px;
}

@media screen and (max-width:1000px)
{
  #text_about{
    margin:50px;
    margin-top:10vh;
    margin-bottom:100px;
    width:90%;
    margin-left:auto;
    margin-right:auto;
    padding:40px;
    font-size:24px;
    color: #ffffff;
    text-align: justify;
    font-weight: 100;
  }

  video{
    width:90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom:100px;
  }
}

.centerDoc
{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top:100px;
  margin-left:auto;
  margin-right:auto;
  margin-bottom:100px;
  font-family: 'Libre Franklin', sans-serif;
  padding:10px;
  background-color:rgb(129, 216, 236);
  background: #81D8EC;

}


h6{
  color:black;
  float:right;
  margin-right:250px;
  margin-top:20px;
  padding: 20px;
}

/* Three columns side by side */
.column {
  margin-bottom: 16px;
  margin:auto 100px;
  padding: 0 8px;
  text-align:center;
}



/* Display the columns below each other instead of side by side on small screens */
@media screen and (max-width: 1350px) {
  .column {
    width: 100%;
    margin-left:0px;
    display: block;
  }
}

/* Add some shadows to create a card effect */
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-bottom:10vh;
  background-color: #60b8cc;
}

@media screen and(max-width:800px){

  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin-bottom:10vh;
    background-color: #60b8cc;
    height:900px;
    size: 50%;

  }
  


}

@keyframes header2 {
0% {opacity:0}
25% {opacity:0.5}
100%{opacity:1}
0%{ transform: translateX(-100px); }
100%{ transform: translate(0px); }
}

/* Some left and right padding inside the container */
.container {
  width:500px;
  height:100%;
}


/* Clear floats */
.container::after, .row::after {
  content: "";
  clear: both;
  display: table;
}

.title {
  color: hsl(189, 100%, 82%);
}

#rysavyAlbum img
{
  height:600px;
}



.button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: rgb(24, 157, 197);
  text-align: center;
  cursor: pointer;
  width: 100%;
}

.button:hover {
  background-color:rgb(33, 204, 216);
  color:black;
}

input[type=text], select, textarea {
  width: 100%;
  color:black;
  padding: 12px;
  box-sizing: border-box;
  font-size: large;
  margin-top: 6px;
  margin-bottom: 36px;
  border: 0px;
  resize: vertical;
  background-color: white;

}


input[type=email] 
{
  width: 100%;
  padding: 12px;
  color:black;
  box-sizing: border-box;
  border: 0px;
  font-size: large;
  margin-top: 6px;
  margin-bottom: 36px;
  resize: vertical;
  background-color: white;
  
}


input[type=submit] {
  background-color: white;
  color: black;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer; 
  display:block;
  margin-left: auto;
  margin-right: auto;
}

input[type=submit]:hover {
  background-color: #60b8cc; 
  border:1px solid black;
}

.container {
  border-radius: 5px;
  background-color: #60b8cc; 
  padding: 20px;
  margin-bottom:10vh;
  width:500px;
  height:500px;
  
}

label 
{
  color:rgb(0, 12, 15);
}


button{
  padding:5px;
  margin:5px;
  background-color: #1F7B8F;
  background-color: #60b8cc;
  border:1px solid black;
  margin-top:35px;
  border-radius:5px;
  float:right;
}

button:hover{
  color:white;
  background-color: teal;
  cursor: pointer;
}

a{
  text-decoration: none;
  color:black;
}


.contact
{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 100px;
  margin-bottom: 200px;
  opacity: 0;
  animation: logo;
  animation-delay: 1s;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  transition-property: transform;
  transition-duration: 3s;
}

#homeMessage, #centerPic

{
  font-size: 65px;

}

@media screen and (max-width:800px) {

  #homeMessage , #centerPic

{
  font-size: 45px;

}


}


